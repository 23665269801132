import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getActions,
  saveCompletedActions,
  saveUserPlaneStartsOn,
  updateCompletedActions,
  updateUserData,
} from "../../store/actions/dashboardAction";
import ConfirmationModal from "../../modals/confirmation-modal";
import ReturnModal from "../../modals/return-modal";
import ExitModal from "../../modals/exit-modal";
import { getUserProfile } from "../../store/actions/authAction";
import { journeyData, journeyLables } from "../../utils/jorney-data";
import { allWeeks, allWeeksActions, getLocalData } from "../../utils/functions";
import ReferFriend from "../../modals/refer-friend-modal";
import TestimonialModal from "../../modals/testimonial-modal";

const videos = [
  "https://player.vimeo.com/video/849761866?h=a14d2163da",
  "https://player.vimeo.com/video/849761901?h=59a2f25bfe",
  "https://player.vimeo.com/video/849761828?h=2f387de358",
  "https://player.vimeo.com/video/849760369?h=314d3d8891",
  "https://player.vimeo.com/video/849923836?h=58b93a2342",
  "https://player.vimeo.com/video/832390225?h=8d1b7366be",
  "https://player.vimeo.com/video/850940848?h=6ffd3cb604",
  "https://player.vimeo.com/video/853826423?h=6d89964d6b",
];

const Journey = ({ loginUser, history }) => {
  const dispatch = useDispatch();
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentWeekStep, setCurrentWeekStep] = useState(null);
  const [enableWeeks, setEnableWeeks] = useState(null);
  const [showStep, setShowStep] = useState(null);
  const [hide, setHide] = useState(false);
  const [actions, setActions] = useState(allWeeksActions);
  const [showConfirm, setShowCofirm] = useState(false);
  const [allComplete, setAllComplete] = useState([]);
  const [btnClicked, setBtnClicked] = useState(null);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showExitModal, setExisModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [referFriend, setReferFriend] = useState(false);
  const [modalType, setModalType] = useState("");
  const [testimonial, setTestimonial] = useState(false);

  useEffect(() => {
    if(loginUser){
      const redirectUrl = sessionStorage.getItem("redirectUrl");
  
          if (redirectUrl) {
            history.push(redirectUrl);
            sessionStorage.removeItem("redirectUrl");
            return;
          }
        }

    if (loginUser?.contentDay) {
      let currentWeekNo = 1,
        step = 1;

      currentWeekNo = Math.ceil(loginUser?.contentDay / 7);
      setEnableWeeks(currentWeekNo);
      setCurrentWeek(currentWeekNo);

      if (loginUser?.isClient) {
        if (loginUser?.contentDay <= 7) {
          step = 1;
          if (loginUser?.contentDay <= 5) {
            setEnableWeeks(1);
          } else {
            setEnableWeeks(2);
          }
        } else if (loginUser?.contentDay > 7 && loginUser?.contentDay <= 91) {
          step = 2;
        } else if (loginUser?.contentDay > 91 && loginUser?.contentDay <= 98) {
          step = 3;
        } else {
          step = 4;
        }
      }
      setCurrentWeekStep(step);
      showStepHandler(step);
      // getNotes(currentWeekNo, day);
    }
  }, [loginUser]);

  useEffect(() => {
    saveActions();
  }, []);

  const saveActions = async () => {
    const res = await dispatch(getActions());

    if (res?.completed_index) {
      setActions(res?.completed_index);
    }
    if (res?.all_complete) {
      setAllComplete(res?.all_complete?.split(",").map((val) => +val));
    }
    if (!res?.completed_index) {
      saveCompletedHandler();
    }
  };

  useEffect(() => {
    if (loginUser?.contentDay && autoScroll) {
      setTimeout(() => {
        document?.getElementById("open-toggle")?.scrollIntoView();
      }, 100);
    }
  }, [loginUser?.contentDay, autoScroll]);

  const handleCompleteHandler = (step, week, actionIndex, key) => {
    const selectedAction = actions[step]?.find((val) => val.week === week);

    if (!selectedAction) {
      return false;
    }

    const data = { ...actions };
    let str = selectedAction[key];
    selectedAction[key] = str?.includes(actionIndex)
      ? str?.includes(`${actionIndex},`)
        ? str?.replace(`${actionIndex},`, ``)
        : str?.includes(`,${actionIndex}`)
        ? str?.replace(`,${actionIndex}`, ``)
        : str?.replace(actionIndex, ``)
      : str?.concat(str?.length ? `,${actionIndex}` : actionIndex);

    setActions(data);
    const payload = {
      completed_index: data,
      all_complete: "",
    };
    dispatch(updateCompletedActions(payload));
  };

  const showStepHandler = async (step) => {
    setShowStep(showStep === step ? null : step);
  };

  const saveCompletedHandler = (step) => {
    const payload = {
      completed_index: actions,
    };

    dispatch(saveCompletedActions(payload));
  };

  const updateData = async (val) => {
    const payload = {
      value: val,
    };

    setLoading(true);
    const res = await dispatch(updateUserData(payload));
    setLoading(false);

    if (res.status === "success") {
      dispatch(getUserProfile());
      setAutoScroll(false);
    }
  };

  const saveHandler = async () => {
    const payload = {
      completed: 1,
    };
    setLoading(true);
    const res = await dispatch(saveUserPlaneStartsOn(payload));
    setLoading(false);
    if (res.status === "success") {
      await dispatch(getUserProfile());
      setAutoScroll(false);
    }
  };
  useEffect(() => {
    if (loginUser?.completedDays === 0 || loginUser?.isClient === false) {
      setShowStep(0);
      setCurrentWeek(0);
    }
  }, [loginUser?.completedDays, loginUser?.isClient]);

  const checkingChecked = (step, week, index, data) => {
    const selectedAction = actions[step]?.find((val) => val.week === week);

    return selectedAction?.[data]?.includes(index);
  };

  const onStep4ButtonsClick = (index) => {
    if (index == 0) {
      setTestimonial(true);
    } else if (index == 1) {
      setReferFriend(true);
      setModalType("Schedule Success Scream Interview!");
    } else if (index == 2) {
      window.open("https://bit.ly/3vfsVGL");
    } else {
      window.open("https://www.facebook.com/BreakthroughM2Weightloss/reviews");
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div className="card-body">
          {loginUser?.isClient && (
            <div className="card mb-3">
              {loginUser?.lowCalDays > 14 &&
                !loginUser?.step3Begin &&
                !hide &&
                loginUser?.completed === 0 && (
                  <div
                    className="card mb-3 align-items-center"
                    style={{ padding: 10, paddingBottom: 30 }}>
                    <h4 className="card-header pb-3 fw-semibold">
                      You're doing great! Are you ready for the next step?
                    </h4>
                    <div className="row mt-4">
                      <div
                        className="col-md-6"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <h4
                          className="tag-val"
                          style={{ fontSize: 20 }}>
                          YES
                        </h4>
                        <p
                          className="tag"
                          style={{ height: 70 }}>
                          If you are ready to begin transition, you will end
                          low-cal and move into Step 3
                        </p>
                        <button
                          onClick={() => {
                            setBtnClicked(2);
                            setShowCofirm(true);
                          }}
                          className="btn-admin btn-outline-info">
                          BEGIN TRANSITION
                        </button>
                      </div>

                      <div
                        className="col-md-6 margin-top-mobile-20"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <h4
                          className="tag-val"
                          style={{ fontSize: 20 }}>
                          NO
                        </h4>
                        <p
                          className="tag"
                          style={{ height: 70 }}>
                          If you choose no, your preference will be saved and
                          you can move into transition when you're ready.
                        </p>
                        <button
                          type="button"
                          onClick={() => setHide(true)}
                          className="btn-admin btn-outline-info">
                          I'M NOT READY YET
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {loginUser?.step3Begin === 1 &&
                loginUser?.completed === 0 &&
                loginUser?.completedDays > 0 && (
                  <div
                    className="card mb-3 align-items-center"
                    style={{ padding: 10, paddingBottom: 30 }}>
                    <h4 className="card-header pb-3 fw-semibold">
                      You've decided to end low-cal, now you have two choices.
                    </h4>
                    <div className="row mt-4">
                      <div
                        className="col-md-6"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <h4
                          className="tag-val"
                          style={{ fontSize: 20 }}>
                          RETURN
                        </h4>
                        <p
                          className="tag"
                          style={{ height: 70 }}>
                          Go through Step 4 with the intention of returning to
                          low-cal and finish what you started.
                        </p>
                        <button
                          onClick={() => setShowReturnModal(true)}
                          className="btn-admin btn-outline-info">
                          LEARN MORE
                        </button>
                      </div>

                      <div
                        className="col-md-6 margin-top-mobile-20"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <h4
                          className="tag-val"
                          style={{ fontSize: 20 }}>
                          EXIT
                        </h4>
                        <p
                          className="tag"
                          style={{ height: 70 }}>
                          Go through Step 4 ready to exit the Breakthrough M2
                          weight loss program.
                        </p>
                        <button
                          type="button"
                          onClick={() => setExisModal(true)}
                          className="btn-admin btn-outline-info">
                          LEARN MORE
                        </button>
                      </div>
                    </div>
                    <h6 className="pb-3 fw-semibold mt-4">
                      <a
                        role="button"
                        onClick={() => {
                          setBtnClicked(1);
                          setShowCofirm(true);
                        }}
                        style={{ color: "#088eac" }}>
                        Click here{" "}
                      </a>
                      to cancel transition and go back to step 2.
                    </h6>
                  </div>
                )}
              {loginUser?.completed === 1 && (
                <p
                  style={{
                    fontSize: 18,
                    height: 130,
                    paddingTop: 50,
                    margin: "auto",
                  }}
                  className="card-header pb-3 fw-semibold">
                  Congratulations, you have completed your program!
                </p>
              )}
            </div>
          )}

          <div
            className="accordion"
            id="accordionExample">
            {Object.keys(journeyData).map((valS, i) => (
              <div
                className="accordion-item"
                style={{
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                }}>
                <h2
                  className="accordion-header"
                  id="headingOne">
                  <button
                    className={`accordion-button ${
                      showStep === i ? "" : "collapsed"
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style={{ fontWeight: 900, fontSize: 16 }}
                    onClick={() => {
                      showStepHandler(i);
                    }}>
                    {journeyLables[valS]}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    showStep === i ? "show" : ""
                  }`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  {(
                    valS !== "breakthroughBasics" ? loginUser?.isClient : true
                  ) ? (
                    <div className="accordion-body">
                      {valS === "breakthroughBasics" && (
                        <div
                          style={{
                            backgroundColor: "#296F8B",
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}>
                          <p
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                            }}>
                            These videos cover the first 13 pages of the
                            Breakthrough M2 Manual.
                            <br />
                            You will learn about our founder and company, the
                            science behind the weight loss drops, the 4 steps of
                            our weight loss program, and what your daily
                            commitment needs to be for a successful journey.
                            <br />
                          </p>
                          <p
                            style={{
                              padding: 20,
                              paddingTop: 5,
                            }}>
                            Video #8 provides package explanations, pricing, and
                            guidance about which package will best help you meet
                            your weight loss to wellness goals.
                          </p>
                        </div>
                      )}
                      {valS === "step1" && (
                        <div
                          style={{
                            backgroundColor: "#0c4e5f",
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}>
                          <p
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                            }}>
                            5 Prep Days = 5 days of education and preparation
                            <br />
                            -followed by- <br /> 2 High Fat Days = Start your
                            drops and prepare your fat reserves
                            <br />
                          </p>
                          <p
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                              paddingTop: 5,
                            }}>
                            The first video goes over the basics of how to
                            effectively use your 5 Prep Days. The next set of
                            videos gives you details and preparation tips for
                            Step 2 Low-Calorie. The last set of videos covers
                            the specific instructions about doing your 2
                            High-Fat Days properly.
                          </p>
                          <p
                            style={{
                              padding: 20,
                              paddingTop: 5,
                            }}>
                            Before your 2 High-Fat Days, watch these videos in
                            order and complete the action steps! <br /> READ
                            Steps 1 & 2 of your manual during your Prep Week.
                            <br /> SUCCESS STARTS HERE!
                          </p>
                        </div>
                      )}
                      {valS === "step2" && (
                        <div
                          style={{
                            backgroundColor: "#73bacb",
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}>
                          <p
                            style={{
                              padding: 20,
                            }}>
                            This step is dedicated to fat loss. Along with
                            taking your Weight Loss Supplements as directed, you
                            will be the most successful if you adhere to the
                            specific food lists, portion sizes, and water
                            drinking instructions. Using your Daily Habit
                            Tracker and all the support resources we offer will
                            help you stay focused and achieve your goal.
                          </p>
                        </div>
                      )}
                      {valS === "step3" && (
                        <div
                          style={{
                            backgroundColor: "#e6605b",
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}>
                          <p
                            style={{
                              padding: 20,
                            }}>
                            You are now exiting the low-calorie, fat-loss phase.
                            Follow these instructions for two days and depending
                            on your goal, move into Step 4 Transition or the
                            Interim Phase.
                          </p>
                        </div>
                      )}
                      {valS === "step4" && (
                        <div
                          style={{
                            backgroundColor: "#6f8f40",
                            color: "#fff",
                            fontSize: 16,
                            lineHeight: 1.5,
                          }}>
                          <p
                            style={{
                              padding: 20,
                            }}>
                            Congratulations! You are now at a new weight! To
                            support your weight loss, you MUST do the Transition
                            Step as directed. You are continuing to build habits
                            for sustainable, lifelong health and wellness!
                          </p>
                        </div>
                      )}
                      {journeyData[valS]
                        .filter((data, j) =>
                          journeyLables[valS] === "Step 1 - Prep week" ||
                          valS === "breakthroughBasics" ||
                          valS === "step4"
                            ? 1
                            : showStep === currentWeekStep
                            ? data.week === currentWeek
                            : j === 0,
                        )
                        ?.map((data, i) => (
                          <>
                            {journeyLables[valS] === "Step 1 - Prep week" &&
                              (data?.week === "1a" || data?.week === "1ae") && (
                                <div className="card-header pb-3 fw-semibold">
                                  <h4>
                                    {data?.week === "1a"
                                      ? "Prep week"
                                      : "High Fat Days"}
                                  </h4>
                                  {data?.week === "1a" ? (
                                    <p
                                      className="text-muted"
                                      style={{ marginTop: -10 }}>
                                      Days 1-5 please watch videos 1-12 Learn{" "}
                                      <br />
                                      about the Prep week, Step 2, and the
                                      high-fat days.
                                    </p>
                                  ) : null}
                                </div>
                              )}

                            {valS === "step4" && (
                              <h4 className="card-header pb-3 fw-semibold">
                                {i === 2
                                  ? "Action Steps For Sustainability (you and BTM2)!"
                                  : i === 3 &&
                                    "Final Action Steps For Your Breakthrough M2 Weight Loss to Wellness Journey!"}
                              </h4>
                            )}

                            <div className="card-body text-body">
                              <ol className="list-group-numbered list-unstyled">
                                {data?.actionSteps?.map((step, actionIndex) => (
                                  <>
                                    {valS === "step1" &&
                                      data?.week === "1b" &&
                                      (actionIndex === 0 ||
                                        actionIndex === 3 ||
                                        actionIndex === 5) && (
                                        <h6 className="card-header pb-3 fw-semibold">
                                          {actionIndex === 0
                                            ? "High-Fat Day #1 Instructions"
                                            : actionIndex === 3
                                            ? "High-Fat Day #2 Instructions"
                                            : actionIndex === 5 &&
                                              "During the 2 High-Fat Days"}
                                        </h6>
                                      )}
                                    {valS === "step3" &&
                                      (actionIndex === 0 ||
                                        actionIndex === 2 ||
                                        actionIndex === 4) && (
                                        <h6 className="card-header pb-3 fw-semibold">
                                          {actionIndex === 0
                                            ? "Day #1 Instructions"
                                            : actionIndex === 2
                                            ? "Day #2 Instructions"
                                            : actionIndex === 4 &&
                                              "During the 2 Step 3 Days"}
                                        </h6>
                                      )}
                                    <div className="row col-md-12">
                                      <div className="col-sm-1 col-md-3"></div>
                                      <div
                                        className="col-sm-10 col-md-6"
                                        style={{
                                          padding: 5,
                                          border: "1px dotted lightgrey",
                                          borderTop:
                                            !actionIndex ||
                                            (valS === "step3" &&
                                              (actionIndex === 2 ||
                                                actionIndex === 4)) ||
                                            (valS === "step1" &&
                                              data?.week === "1b" &&
                                              (actionIndex === 3 ||
                                                actionIndex === 5))
                                              ? "1px dotted lightgrey"
                                              : "none",
                                          borderBottom:
                                            actionIndex ===
                                              data?.actionSteps?.length - 1 ||
                                            (valS === "step3" &&
                                              (actionIndex === 1 ||
                                                actionIndex === 3)) ||
                                            (valS === "step1" &&
                                              data?.week === "1b" &&
                                              (actionIndex === 2 ||
                                                actionIndex === 4))
                                              ? "1px dotted lightgrey"
                                              : "none",
                                        }}>
                                        <input
                                          onClick={() =>
                                            handleCompleteHandler(
                                              valS,
                                              data?.week,
                                              actionIndex,
                                              "actionSteps",
                                            )
                                          }
                                          checked={checkingChecked(
                                            valS,
                                            data?.week,
                                            actionIndex,
                                            "actionSteps",
                                          )}
                                          style={{
                                            // marginRight: 10,
                                            marginTop: 0,
                                            cursor: "pointer",
                                            borderRadius: "50%",
                                            border: "1px solid #ccc",
                                            zIndex: 1,
                                          }}
                                          className="form-check-input form-check-input-success col-md-1"
                                          type="checkbox"
                                        />
                                        {valS === "step4" && i === 2 ? (
                                          <a
                                            role="button"
                                            onClick={() =>
                                              onStep4ButtonsClick(actionIndex)
                                            }>
                                            <li
                                              className="col-md-10"
                                              style={{
                                                marginTop: -20,
                                                marginLeft: 30,
                                                zIndex: 0,
                                                color: "#088eac",
                                              }}>
                                              {step}
                                            </li>
                                          </a>
                                        ) : (
                                          <li
                                            className="col-md-10"
                                            style={{
                                              marginTop: -20,
                                              marginLeft: 30,
                                              zIndex: 0,
                                              color:
                                                checkingChecked(
                                                  valS,
                                                  data?.week,
                                                  actionIndex,
                                                  "actionSteps",
                                                ) && "green",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: step,
                                            }}></li>
                                        )}
                                      </div>
                                      <div className="col-sm-1 col-md-3"></div>
                                    </div>
                                  </>
                                ))}

                                {journeyLables[valS] ===
                                  "Step 1 - Prep week" && (
                                  <h6
                                    style={{
                                      marginTop: 20,
                                      marginBottom: -50,
                                      color: "#333",
                                      marginLeft: -15,
                                    }}
                                    className="card-header  fw-semibold">
                                    {/* {data?.videos?.length === 3
                                    ? ""
                                    : "How to prepare for Step 2 Low Cal"} */}
                                  </h6>
                                )}
                                <div className="row">
                                  {data?.videos?.map((video, videoIndex) => (
                                    <div
                                      className="col-md-6"
                                      style={{
                                        marginTop: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        height: 320,
                                        position: "relative",
                                        borderBottom: "2px solid #088eac",
                                        paddingBottom: 30,
                                      }}>
                                      {(valS === "step1"
                                        ? data?.week === "1a"
                                        : true) && (
                                        <div
                                          className="form-check"
                                          style={{ marginLeft: -80 }}>
                                          <input
                                            style={{
                                              marginRight: 10,
                                              cursor: "pointer",
                                              borderRadius: "50%",
                                              border: "1px solid #ccc",
                                            }}
                                            className="form-check-input form-check-input-success col-md-1"
                                            type="checkbox"
                                            onChange={() => {
                                              handleCompleteHandler(
                                                valS,
                                                data?.week,
                                                videoIndex,
                                                "videos",
                                              );
                                            }}
                                            checked={checkingChecked(
                                              valS,
                                              data?.week,
                                              videoIndex,
                                              "videos",
                                            )}
                                            id={videoIndex}
                                          />
                                          <label
                                            style={{
                                              fontWeight: 900,
                                              fontSize: 16,
                                            }}
                                            className="fb-label pt-1"
                                            for={videoIndex}>
                                            {video.title}
                                          </label>
                                        </div>
                                      )}
                                      {video?.link?.includes(
                                        "https://www.youtube.com",
                                      ) ? (
                                        <div
                                          className="aspect-ratio"
                                          style={{
                                            top: 0,
                                            left: 0,
                                            marginTop: 15,
                                          }}>
                                          <iframe
                                            src={video?.link}
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowfullscreen></iframe>
                                        </div>
                                      ) : (
                                        <iframe
                                          src={video.link}
                                          frameborder="0"
                                          allow="autoplay; fullscreen; picture-in-picture"
                                          style={{
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            marginTop: 15,
                                          }}
                                          title="Step 4 - Healthy Habits &amp; What to Watch For"></iframe>
                                      )}

                                      {video.bottomLinkText && (
                                        <a
                                          href={video?.bottomLink}
                                          target="_blank"
                                          style={{
                                            position: "absolute",
                                            color: "#088eac",
                                            fontSize: 18,
                                            fontWeight: 600,
                                            paddingTop: 10,
                                            bottom: 5,
                                          }}>
                                          {video.bottomLinkText}
                                        </a>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </ol>
                            </div>

                            {valS === "step4" && i === 2 && (
                              <div
                                style={{
                                  backgroundColor: "#36889c",
                                  color: "#fff",
                                  fontSize: 16,
                                  lineHeight: 1.5,
                                }}>
                                <p
                                  style={{
                                    padding: 20,
                                  }}>
                                  90/10 Life - You have successfully finished
                                  Step 4 Transition! Now what? You and
                                  Breakthrough M2 have successfully worked
                                  together to establish healthy habits, a new
                                  perspective, a new outlook, and a new start.
                                  Your new lifestyle can include lots of new
                                  healthy foods and some of your old favorites.
                                  The 90/10 Lifestyle allows for those
                                  alternatives that you won’t eat all the time
                                  but would love to have once in a while. This
                                  freedom to choose is very important. Not
                                  feeling restricted is what allows you to know
                                  that you can make wise choices.
                                </p>
                              </div>
                            )}
                          </>
                        ))}

                      {valS === "breakthroughBasics" &&
                        !loginUser?.isClient && (
                          <div
                            className="row"
                            style={{
                              paddingLeft: 0,
                              marginTop: 50,
                              marginBottom: 30,
                            }}>
                            <div className="col-md-6 text-center">
                              <a
                                role="button"
                                className="btn col-md-9"
                                target="_blank"
                                style={{ fontSize: 18, fontWeight: 900 }}
                                href={
                                  "https://tools.aiotbapp.com/widget/bookings/btm2-nadine"
                                }>
                                Book a Phone Consultation
                              </a>
                            </div>
                            <div className="col-md-6 text-center">
                              <a
                                role="button"
                                className="btn col-md-9"
                                target="_blank"
                                style={{ fontSize: 18, fontWeight: 900 }}
                                href={`https://alulawellness.com/product-category/breakthrough-m2/starter-packages?mo_jwt_token=${getLocalData(
                                  "mo_jwt_token",
                                )}`}>
                                I'm Ready to Purchase
                              </a>
                            </div>
                          </div>
                        )}

                      {valS === "step4" && (
                        <div
                          style={{
                            backgroundColor: "#f4a869",
                            color: "#fff",
                            fontSize: 20,
                            textAlign: "center",
                            fontWeight: 600,
                          }}>
                          <p
                            style={{
                              padding: 10,
                            }}>
                            Thank you, from all of us at Breakthrough M2 Weight
                            Loss to Wellness!
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="accordion-body">
                      <p>
                        Content will be displayed here once your program begins
                        and you have signed your{" "}
                        <a
                          href={`https://alulawellness.com/health-form?mo_jwt_token=${getLocalData(
                            "mo_jwt_token",
                          )}`}
                          target="_blank">
                          Health Form
                        </a>
                        .
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ReferFriend
        modalIsOpen={referFriend}
        setIsOpen={setReferFriend}
        modalType={modalType}
        closeModal={() => setReferFriend(false)}
      />
      <TestimonialModal
        modalIsOpen={testimonial}
        setIsOpen={setTestimonial}
        closeModal={() => setTestimonial(false)}
      />
      <ConfirmationModal
        modalIsOpen={showConfirm}
        setIsOpen={setShowCofirm}
        onClickHandler={() =>
          btnClicked === 4 ? saveHandler() : updateData(btnClicked == 2 ? 1 : 0)
        }
        isLoading={loading}
        btnClicked={btnClicked}
        closeModal={() => setShowCofirm(false)}
        key={btnClicked}
      />
      <ReturnModal
        modalIsOpen={showReturnModal}
        setIsOpen={setShowReturnModal}
        closeModal={() => setShowReturnModal(false)}
        loginUser={loginUser}
        setBtnClicked={setBtnClicked}
        setShowCofirm={setShowCofirm}
      />
      <ExitModal
        modalIsOpen={showExitModal}
        setIsOpen={setExisModal}
        closeModal={() => setExisModal(false)}
        setBtnClicked={setBtnClicked}
        setShowCofirm={setShowCofirm}
      />
    </div>
  );
};

export default Journey;
